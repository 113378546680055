import Vue from 'vue'
import VueRouter from 'vue-router'
import { Message } from 'element-ui';


// 路由懒加载，对路径进行分组打包

const Login = () =>
    import ( /* webpackChunkName: "login_home_welcome" */ '../components/Login.vue')
const Home = () =>
    import ( /* webpackChunkName: "login_home_welcome" */ '../components/Home.vue')
const Welcome = () =>
    import ( /* webpackChunkName: "login_home_welcome" */ '../components/Welcome.vue')
const ChangePassword = () =>
    import ( /* webpackChunkName: "login_home_welcome" */ '../components/ChangePassword.vue')

const Users = () =>
    import ( /* webpackChunkName: "admin_management" */ '../views/Users.vue')
const UserRole = () =>
    import ( /* webpackChunkName: "admin_management" */ '../views/UserRole.vue')

const SupplierType = () =>
    import ( /* webpackChunkName: "admin_base" */ '../views/SupplierType.vue')
const Supplier = () =>
    import ( /* webpackChunkName: "admin_base" */ '../views/Supplier.vue')
const DemandType = () =>
    import ( /* webpackChunkName: "admin_base" */ '../views/DemandType.vue')
const Item = () =>
    import ( /* webpackChunkName: "admin_base" */ '../views/Item.vue')
const Consignee = () =>
    import ( /* webpackChunkName: "admin_base" */ '../views/Consignee.vue')
const Delivery = () =>
    import ( /* webpackChunkName: "admin_base" */ '../views/Delivery.vue')
const SubscriptionPerson = () =>
    import ( /* webpackChunkName: "admin_base" */ '../views/SubscriptionPerson.vue')
const DemandPublish = () =>
    import ( /* webpackChunkName: "admin_base" */ '../views/DemandPublish/DemandPublish.vue')

const ItemReport = () =>
    import ( /* webpackChunkName: "report" */ '../views/ItemReport.vue')
const ConsigneeReport = () =>
    import ( /* webpackChunkName: "report" */ '../views/ConsigneeReport.vue')

Vue.use(VueRouter)

const routes = [
    { path: '/', redirect: '/login' },
    { path: '/login', component: Login },
    {
        path: '/home',
        component: Home,
        redirect: '/welcome',
        children: [
            { path: '/welcome', component: Welcome },
            { path: '/changepassword', component: ChangePassword },
            { path: '/users', component: Users },
            { path: '/userrole', component: UserRole },
            { path: '/suppliertype', component: SupplierType },
            { path: '/Supplier', component: Supplier }, //供应商
            { path: '/DemandType', component: DemandType }, //需求类型 
            { path: '/Item', component: Item }, //品质
            { path: '/Consignee', component: Consignee }, //收货单位
            { path: '/Delivery', component: Delivery }, //收货单位
            { path: '/SubscriptionPerson', component: SubscriptionPerson }, //业务人员
            { path: '/DemandPublish', component: DemandPublish }, //业务人员
            { path: '/ItemReport', component: ItemReport }, //报表
            { path: '/ConsigneeReport', component: ConsigneeReport }, //报表


        ]
    },
    {
        path: '*',
        redirect: '/404',
        hidden: true
    }
]

const router = new VueRouter({
        mode: 'hash', //history
        base: process.env.BASE_URL,
        routes
    })
    // 挂载路由导航守卫 beforeEach,to:想去哪个路由来，from：从哪个路径来,next是一个函数，表示放行
router.beforeEach((to, from, next) => {
    if (to.path === '/login') return next();
    // 获取token
    // const tokenStr = window.sessionStorage.getItem('token');
    // if (!tokenStr) {
    //     Message.error("未取得有效token信息");
    //     return next('/login');
    // }
    // token有值，则放行

    next();
})
export default router