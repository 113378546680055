export default {
    // 日期格式化 fmt 格式化字符如 'yyyy-MM-dd hh:mm:ss' v 可以是日期或字符串
    datetimeFormat: function(v, fmt) {
        if ((typeof v).toLocaleLowerCase() == 'string') { v = new Date(v.replace(/-/g, '/')) }
        var o = {
            'M+': v.getMonth() + 1, // 月份
            'd+': v.getDate(), // 日
            'h+': v.getHours(), // 小时
            'm+': v.getMinutes(), // 分
            's+': v.getSeconds(), // 秒
            'q+': Math.floor((v.getMonth() + 3) / 3), // 季度
            'S': v.getMilliseconds() // 毫秒
        }
        if (/(y+)/.test(fmt)) { fmt = fmt.replace(RegExp.$1, (v.getFullYear() + '').substr(4 - RegExp.$1.length)) }
        for (var k in o) {
            if (new RegExp('(' + k + ')').test(fmt)) {
                fmt = fmt.replace(RegExp.$1, (RegExp.$1.length == 1) ? (o[k]) : (('00' + o[k]).substr(('' + o[k]).length)))
            }
        }
        return fmt
    },
    // 获取日期的星期 v=日期字符串或日期对象 返回值 0-6对应 周日-周六
    getWeek: function(v) {
        if ((typeof v).toLocaleLowerCase() == 'string') { v = new Date(v.replace(/-/g, '/')) }
        var end = v.getDay()
        return end
    },
    // 字符串转日期 v必须为 -格式 如 2020-05
    toDateTime: function(v) {
        if ((typeof v).toLocaleLowerCase() == 'string') { v = new Date(v.replace(/-/g, '/')) }
        return v
    },
    // 用于将数组进行name匹配 这里用作export2excel.js插件的智能字段识别导出
    formatJson: function(filterVal, jsonData) {
        return jsonData.map(v => filterVal.map(j => v[j]))
    },

    //一、 判断是否为支付宝坏境
    isAlipayClient() {
        if (navigator.userAgent.indexOf('AlipayClient') > -1) {
            return true
        }
        return false
    },

    //二、 判断安卓还是ios
    phoneOs() {
        var u = navigator.userAgent,
            isAndroid = u.indexOf('Android') > -1 || u.indexOf('Linux') > -1,
            isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/)
        if (isAndroid) {
            return 'Android'
        }
        if (isiOS) {
            return 'IOS'
        }
        return ''
    },
    //三、 判断是否是微信
    isWeixin() {
        var ua = window.navigator.userAgent.toLowerCase()
        if (ua.match(/MicroMessenger/i) == 'micromessenger') {
            return true
        } else {
            return false
        }
    },

    //四、判断是否PC端还是移动端
    _isMobile() {
        let flag = window.navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)
        return flag;
    },

    flexWidth(prop, tableData, title, num = 0) {
        if (tableData.length === 0) { //表格没数据不做处理
            return;
        }
        let flexWidth = 0; //初始化表格列宽
        let columnContent = ''; //占位最宽的内容
        let canvas = document.createElement("canvas");
        let context = canvas.getContext("2d");
        context.font = "14px Microsoft YaHei";
        if ((prop === '') && title) { //标题长内容少的，取标题的值,
            columnContent = title
        } else { // 获取该列中占位最宽的内容
            let index = 0;
            for (let i = 0; i < tableData.length; i++) {
                const now_temp = tableData[i][prop] + '';
                const max_temp = tableData[index][prop] + '';
                const now_temp_w = context.measureText(now_temp).width
                const max_temp_w = context.measureText(max_temp).width
                if (now_temp_w > max_temp_w) {
                    index = i;
                }
            }
            columnContent = tableData[index][prop]
                //比较占位最宽的值跟标题、标题为空的留出四个位置
            const column_w = context.measureText(columnContent).width
            const title_w = context.measureText(title).width
            if (column_w < title_w) {
                columnContent = title || '留四个字'
            }
        }
        // 计算最宽内容的列宽
        let width = context.measureText(columnContent);
        flexWidth = width.width + 40 + num
        return flexWidth + 'px';
    },
    getCurrentYearAndMonth() {
        var date = new Date();
        var today = date.getDate();
        if (parseInt(today) < 10) {
            today = "0" + today;
        }
        var dataMonth = date.getMonth() + 1;
        if (parseInt(dataMonth) < 10) {
            dataMonth = "0" + dataMonth;
        }
        return date.getFullYear() + "-" + dataMonth;
    },
    getCurrentDate() {
        var date = new Date();
        var today = date.getDate();
        if (parseInt(today) < 10) {
            today = "0" + today;
        }
        var dataMonth = date.getMonth() + 1;
        if (parseInt(dataMonth) < 10) {
            dataMonth = "0" + dataMonth;
        }
        return date.getFullYear() + "-" + dataMonth + "-" + today;
    },

}